import { color, spacing } from '@/Layouts/theme';
import React, { CSSProperties, useEffect } from 'react';
import { CardDescriptionProps } from './card-description.props';
import { Link } from 'gatsby';

const CARD_PRODUCT: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const BUTTON: CSSProperties = {
  display: 'flex',
  // justifyContent: 'center',
};

const CAPTION_HEADER: CSSProperties = {
  fontSize: '3rem',
  fontWeight: 800,
  margin: 0,
};

const CAPTION_CONTENT: CSSProperties = {
  fontSize: '1.25rem',
  fontWeight: 800,
  margin: 0,
};

const LINK: CSSProperties = {
  color: color.textWhite,
  textDecoration: 'none',
  backgroundColor: color.buttonGreen,
  padding: '8px 30px',
  borderRadius: 30,
};

const ROW: CSSProperties = {
  marginTop: spacing[4],
  marginBottom: spacing[4],
};

export function CardDescription(props: CardDescriptionProps) {
  const {
    captionHeader,
    captionContent,
    linkTo,
    linkToText,
    fade = 'fade-left',
    containerStyle,
    captionHeaderStyle,
    captionContentStyle,
  } = props;



  const rootStyle = { ...CARD_PRODUCT, ...containerStyle };
  const linkStyle = { ...LINK, ...containerStyle };
  const rootCaptionHeaderStyle = { ...CAPTION_HEADER, ...captionHeaderStyle };
  const rootCaptionContentStyle = {
    ...CAPTION_CONTENT,
    ...captionContentStyle,
  };

  return (
    <div style={rootStyle} data-aos={fade}>
      <div style={ROW}>
        <h1 style={rootCaptionHeaderStyle}>{captionHeader}</h1>
      </div>
      <div style={ROW}>
        <h5 style={rootCaptionContentStyle}>{captionContent}</h5>
      </div>
      {linkToText && (
        <div style={{ ...BUTTON, ...ROW }}>
          <Link to={linkTo} style={linkStyle}>
            {linkToText}
          </Link>
        </div>
      )}
    </div>
  );
}
