import React, { CSSProperties, useEffect } from 'react';
import { CardProps } from './card.props';
import { Icon } from 'react-icons-kit';
import { arrows_circle_plus } from 'react-icons-kit/linea/arrows_circle_plus';
import { spacing } from '@/Layouts/theme';

const ROOT: CSSProperties = {
  backgroundColor: 'transparent',
};

const IMAGE: CSSProperties = {
  width: '100%',
  objectFit: 'inherit',
};

const CONTENT: CSSProperties = {
  height: '80%',
};

const FOOTER: CSSProperties = {
  height: '20%',
  textAlign: 'center',
  paddingTop: spacing[5],
};

export function Card(props: CardProps) {
  const {
    content = null,
    imagePath,
    imageName,
    icon = arrows_circle_plus,
    iconSize = 20,
    imageStyle,
    containerStyle,
  } = props;

  const rootImageStyle = { ...IMAGE, ...imageStyle };
  const rootContainerStyle = { ...ROOT, ...containerStyle };

  return (
    <div style={rootContainerStyle} data-aos="fade-up">
      <div style={CONTENT}>
        {content}
        {imagePath && (
          <img src={imagePath} alt={imageName} style={rootImageStyle} />
        )}
      </div>
      <div style={FOOTER}>
        <Icon icon={icon} size={iconSize} />
      </div>
    </div>
  );
}
