import React, { CSSProperties, Fragment, useState, useEffect, ReactElement } from 'react';
import { Carousel as CarouselStrap, Col, Container, Row } from 'react-bootstrap';
import { CarouselProps } from './carousel.props';
import { color, images, spacing } from '../../Layouts/theme';
import { ImageHorizontal, CONTENT_CENTER } from '../../Layouts/styled/main';
import styled from 'styled-components';
import Img from "gatsby-image"
import { getImagesName, getSourceImage, getSourceImageMultiSize } from '@/utils/image-sharp';

const defaultSlideImages: any = [
  {
    name: 'carousel-image-1',
    fileName: 'main-slide-2021-1.jpg',
  },
  {
    name: 'carousel-image-2',
    fileName: 'main-slide-2021-2.jpg',
  },
  {
    name: 'carousel-image-3',
    fileName: 'main-slide-2021-3.jpg',
  },
];

export const ImageMobileBox = styled.div<{ height?: number }>`
  width: 100%;
  height: 480px;
  @media (max-width: 576px) {
    height: ${(props) => `${props.height ?? 360}px`};
  }
  @media (max-width: 768px) {
    height: ${(props) => `${props.height ?? 480}px`};
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
`;

const SectionBox = styled.section`
  display: block;
  @media (max-width: 768px) {
    display: none !important;
  }
  @media (min-width: 1280px) {
    display: none !important;
  }
`;

export function Carousel(props: CarouselProps): ReactElement {
  const {
    speed = 1000,
    containerStyle,
    dataSource,
    imageHeight,
    onClick = null,
  } = props;

  const [mobileImages, setMobileImages] = useState<any[]>();
  const [desktopImages, setDesktopImages] = useState<any[]>();

  useEffect(() => {
    if (dataSource?.mobileImage?.edges) {
      setMobileImages(getImagesName(dataSource.mobileImage.edges));
    }
  }, [dataSource, dataSource.mobileImage]);

  useEffect(() => {
    if (dataSource?.desktopImage?.edges) {
      setDesktopImages(getImagesName(dataSource.desktopImage.edges));
    }
  }, [dataSource, dataSource.desktopImage]);

  return (
    <Fragment key="carousel">
      {/* //? for screen size xs-md */}
      <section className="d-md-none d-block">
        <CarouselStrap id="sm-carousel" style={containerStyle} data-aos="fade-up">
          {mobileImages?.map(({ name, fileName, caption }) => {
            return (
              <CarouselStrap.Item key={`${name}-${fileName}`} interval={speed}>
                <ImageMobileBox height={imageHeight} onClick={() => onClick({ imageName: fileName })}>
                  {/* <ImageHorizontal src={fileName} alt={name} /> */}
                  <Img
                    fluid={getSourceImage(fileName, dataSource.mobileImage.edges, '(min-width: 768px)')}
                    alt={name}
                    style={{
                      position: undefined
                    }}
                    className={'topic-image'}
                  />
                </ImageMobileBox>
                {caption && (
                  <CarouselStrap.Caption>{caption}</CarouselStrap.Caption>
                )}
              </CarouselStrap.Item>
            );
          })}
        </CarouselStrap>
      </section>
      {/* //? for screen size md-1280px */}
      <SectionBox>
        <CarouselStrap id="md-carousel" style={containerStyle} data-aos="fade-up">
          {desktopImages?.map(({ name, fileName, caption }) => {
            return (
              <CarouselStrap.Item key={`${name}-${fileName}`} interval={speed}>
                <ImageMobileBox onClick={() => onClick({ imageName: fileName })}>
                  {/* <ImageHorizontal src={fileName} alt={name} /> */}
                  <Img
                    fluid={getSourceImage(fileName, dataSource.desktopImage.edges, '(min-width: 768px)')}
                    alt={name}
                    style={{
                      position: undefined
                    }}
                    className={'topic-image'}
                  />
                </ImageMobileBox>
                {caption && (
                  <CarouselStrap.Caption>{caption}</CarouselStrap.Caption>
                )}
              </CarouselStrap.Item>
            );
          })}
        </CarouselStrap>
      </SectionBox>
      <section className="hd-size">
        <Container fluid style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <Row
            className="justify-content-center"
            style={{
              height: '50%',
              backgroundColor: color.backgroundWhite,
            }}
          > */}
          <div style={{ maxWidth: 1080 }}>
            <Col style={CONTENT_CENTER}>
              <CarouselStrap id="xl-carousel" style={containerStyle} data-aos="fade-up">
                {desktopImages?.map(({ name, fileName, caption }) => {
                  return (
                    <CarouselStrap.Item
                      key={`${name}-${fileName}`}
                      interval={speed}
                    >
                      <div style={{ width: 1050, height: 480 }} onClick={() => onClick({ imageName: fileName })}>
                        {/* <ImageHorizontal src={fileName} alt={name} /> */}
                        <Img
                          fluid={getSourceImage(fileName, dataSource.desktopImage.edges, '(min-width: 768px)')}
                          alt={name}
                          // style={{
                          //   position: undefined
                          // }}
                          className={'topic-image'}
                        />
                      </div>
                      {caption && (
                        <CarouselStrap.Caption>{caption}</CarouselStrap.Caption>
                      )}
                    </CarouselStrap.Item>
                  );
                })}
              </CarouselStrap>
            </Col>
            {/* </Row> */}
          </div>
        </Container>
      </section>
    </Fragment>
  );
}
