interface SourcesMultiSizeFile {
  mobileImage: {
    edges: Array<any>
  }
  desktopImage: {
    edges: Array<any>
  }
}

interface ImageProps {
  fileName: string
  name: string
  caption?: string
}

export const getSourceImage = (imgName: string, edges: Array<any> | any, size?: string) => {
  const source = edges?.find((edge: any) => edge?.node?.childImageSharp?.fluid?.originalName === imgName)
  if (source) {
    return [
      {
        ...source.node.childImageSharp.fluid,
        ...(size ? { media: size } : undefined)
      }
    ];
  }
  return null
}

export const getSourceImageMultiSize = (imgName: string, data: SourcesMultiSizeFile, size: string) => {
  const { mobileImage, desktopImage } = data;
  const sourceMobile = mobileImage.edges.find((edge: any) => edge?.node?.childImageSharp?.fluid?.originalName === imgName)
  const sourceDesktop = desktopImage.edges.find((edge: any) => edge?.node?.childImageSharp?.fluid?.originalName === imgName)
  if (sourceMobile && sourceDesktop) {
    return [
      sourceMobile.node.childImageSharp.fluid,
      {
        ...sourceDesktop.node.childImageSharp.fluid,
        media: size,
      },
    ];
  }
  return null
}

export const getImagesName = (edges: Array<any>): Array<ImageProps> => {
  if (!edges.length) return [];
  return edges.map(attr => {
    const fileName = attr.node?.childImageSharp?.fluid?.originalName ?? ''
    return fileName ? {
      fileName: fileName,
      name: fileName ? fileName.split(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)[0] : '',
      caption: ''
    } : undefined
  }).filter(Boolean);
}
