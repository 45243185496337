import { color, spacing } from '../../Layouts/theme';
import React, { CSSProperties, ReactElement, useEffect } from 'react';
import { CardProductProps } from './card-product.props';
import AOS from 'aos';
import { IMAGE_STYLE } from '../../Layouts/styled/main';
import styled from 'styled-components';
import Img from "gatsby-image"

const CARD_PRODUCT: CSSProperties = {
  background: 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
};

const TOP: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const ImageBox = styled.div`
  color: #000000;
  text-decoration: none;
  align-items: center;
  padding-top: ${spacing[7] - 10}px;
  padding-bottom: ${spacing[7] - 10}px;
  max-height: 480px;
  @media (max-width: 768px) {
    max-height: 480px;
    padding-top: ${spacing[6]}px;
    padding-bottom: ${spacing[6]}px;
  }
  @media (max-width: 576px) {
    max-height: 360px;
    padding-top: ${spacing[6]}px;
    padding-bottom: ${spacing[6]}px;
  }
`;

export function CardProduct(props: CardProductProps): ReactElement {
  const {
    imageName,
    containerStyle,
    containerImageStyle,
    imageStyle,
    source,
    disableAos = false,
  } = props;

  useEffect(() => {
    if (!disableAos) {
      // AOS.init({
      //   duration: 700,
      //   // disable: 'mobile',
      // });
    }
  }, []);

  const rootStyle = { ...CARD_PRODUCT, ...containerStyle };
  const containerImage: CSSProperties = {
    // ...IMAGE_STYLE,
    // maxWidth: '80%',
    // maxHeight: '90%',
    // width: '100%',
    // height: '100%',
    ...containerImageStyle,
  };

  return (
    <div style={rootStyle} data-aos={!disableAos ? 'fade-up' : undefined}>
      <ImageBox>
        <div style={TOP}>
          {/* <img src={path} style={containerImage} alt={imageName} /> */}
          <Img
            fluid={source}
            alt={imageName}
            className="card-product-resize"
            style={containerImage}
            imgStyle={imageStyle}
          />
        </div>
      </ImageBox>
    </div>
  );
}
